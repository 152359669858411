<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <v-card class="ml-10 mr-10 mt-10 mb-10" style="background: #f5f5f5">
              <v-data-table
                :headers="headers"
                :items="contract"
                sort-by="id"
                class="elevation-1"
                style="background: #f5f5f5"
              >
                <template v-slot:top>
                  <v-toolbar flat elevation="1" style="background: #f5f5f5">
                    <v-toolbar-title>My Advertise</v-toolbar-title>
                    <v-spacer></v-spacer>

                    <!-- Edit New Add -->
                    <v-dialog v-model="dialog" max-width="500px">
                      <v-card>
                        <v-card-title>
                          <span class="headline">Edit Addvertisement</span>
                        </v-card-title>

                        <v-card-text>
                          <v-row>
                            <v-col cols="12">
                              <template>
                                <v-file-input
                                  v-model="editedItem.image"
                                  label="Upload Your Image"
                                  filled
                                  prepend-icon="mdi-camera"
                                ></v-file-input>
                              </template>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                v-model="editedItem.ad_link"
                                label="Add Link"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="editedItem.content"
                                label="Add Content"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="red" text @click="close">Cancel</v-btn>
                          <v-btn color="blue darken-1" text @click="saveEdit"
                            >Save</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-dialog v-model="dialog2" max-width="500px">
                      <template v-slot:activator="{ on, attrs }" v-if>
                        <v-btn color="primary" float-right @click="addnew">
                          <v-icon color="white">mdi-plus</v-icon>Add New
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">New Addvertisement</span>
                        </v-card-title>

                        <v-container class="px-5">
                          <v-row>
                            <v-col cols="12">
                              <template>
                                <v-file-input
                                  v-model="image"
                                  label="Upload Your Image"
                                  filled
                                  prepend-icon="mdi-camera"
                                ></v-file-input>
                              </template>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                v-model="ad_link"
                                label="Add Link"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="content"
                                label="Add Content"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-radio-group v-model="priority">
                                <v-radio
                                  v-for="n in 2"
                                  :key="n"
                                  :label="
                                    n == 2
                                      ? 'For advertise slider'
                                      : `For Single Add`
                                  "
                                  :value="n"
                                  color="black"
                                ></v-radio>
                              </v-radio-group>
                            </v-col>
                          </v-row>
                        </v-container>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="red"
                            text
                            @click.prevent="dialog2 = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            @click="addAddvertisement"
                            elevation="0"
                            >Add</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <!-- <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon> -->
                  <v-btn
                    text
                    small
                    ripple
                    color="red"
                    v-if="item.is_active"
                    @click="action(item)"
                    >Make Inactive</v-btn
                  >
                  <v-btn text small ripple v-else @click="action(item)"
                    >Make Active</v-btn
                  >
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" centered>
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>



<script>
export default {
  data: () => ({
    dialog: false,
    dialog2: false,
    image: "",
    ad_link: "",
    content: "",
    priority: 2,
    text: "",
    snackbar: false,

    headers: [
      {
        text: "Advertisement",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Content", value: "content", sortable: false },
      { text: "Link", value: "ad_link", sortable: false },
      { text: "Clicks", value: "total_click_count" ,align: "center" },
      { text: "Views", value: "total_view_count" ,align: "center" },
      { text: "Priority", value: "priority", align: "center", sortable: false },
      { text: "Show", value: "is_active", align: "center", sortable: false },
      { text: "Action", value: "actions", align: "center" , sortable: false },
    ],
    contract: [],
    contact: {},
    editedIndex: -1,
    editedItem: {
      image: "",
      ad_link: "",
      content: "",
    },
    defaultItem: {
      image: "",
      ad_link: "",
      content: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Advertise" : "Edit Advertise";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialog2(val) {
      val || this.close();
    },
  },
  methods: {
    addnew() {
      this.dialog2 = true;
    },
    initialize() {
      axios.get("ad/admin_ads/").then((response) => {
        this.contract = response.data.data;
        // console.log("All ads ", this.contract);
      });
    },

    addAddvertisement() {
      let formData = new FormData();

      formData.append("image", this.image);
      formData.append("ad_link", this.ad_link);
      formData.append("content", this.content);
      formData.append("priority", this.priority);

      axios
        .post("ad/add_ad/", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          this.text = "Advertise added successfully !";
          this.contract.push(response.data.data);
          this.text = response.data.message;
            this.color = "success";
            this.snackbar = true;
          this.close();
          
        })
        .catch((err) => {
          this.text = "Something went wrong !";
          this.close();
          this.snackbar = true;
        });
    },

    editItem(item) {
      this.editedItem = item;
      this.dialog = true;
    },

    deleteItem(item) {
      if (confirm("Do you really want to delete?")) {
        axios.post(`ad/delete/${item.id}/`).then((response) => {
          this.contract = this.contract.filter(
            (contact) => contact.id !== item.id
          );
        });
      }
    },

    close() {
      this.dialog = false;
      this.dialog2 = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    saveEdit() {
      let formData = new FormData();

      formData.append("image", this.editedItem.image);
      formData.append("ad_link", this.editedItem.ad_link);
      formData.append("content", this.editedItem.content);
      // {
      //     ad_link : this.editedItem.ad_link
      //   }

      // console.log("My ID", this.editedItem);

      axios
        .post(`ad/update/${this.editedItem.id}/`, formData)
        .then((response) => {
          this.text = response.data.message;
            this.color = "success";
            this.snackbar = true;
          this.dialog = false;
        });
    },

    action(item) {
      const index = this.contract.indexOf(item);
      axios.post(`ad/change_status/${item.id}/`).then((response) => {
        Object.assign(this.contract[index], response.data.ad_data);
      });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style>
.v-sheet--offset {
  top: -10px;
  position: relative;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>
